import { createSlice } from '@reduxjs/toolkit';
import { modifyTableData, calcSufficientData } from "./utils";
import { getUsersTableData } from "../users/api"
import { getSessionsTableData } from "../sessions/api"
import { getVideosTableData, saveVideoData } from "../videos/api"
import { getContentTableData } from "../content/api"
import { saveResidentComment, saveSessionComment } from "../../api"
import { getTableOverTimeData, getTableVideoData } from './api';
import {
    OVER_TIME_BUTTONS,
    HOUSE_TYPES,
    COLUMN_VALUE_OPTION,
    TAG_CONTEXT,
    ANNOTATION_TYPE,
    OVER_TIME_BUTTONS as OVER_TIME_MODULES_BUTTONS
} from '../../constants'
import moment from "moment"
import features from "../../features"
import { get } from "lodash";

let app = process.env.REACT_APP_ENV;
let isSDK = app && app.includes("sdk")

export const tableSlice = createSlice({
    name: 'table',
    initialState: {
        loadingTable: false,
        tableData: [],
        annotations: [],
        showInsufficientData: false,

        loadingRowOvertime: [],
        tableOvertime: [],
        tableOvertimeIds: [],
        tableOvertimeSelectedIds: [],
        tableVideo: [],
        selectedOvertimeButtons: [],

        userName: null,

        headerTitle: "users",
        currPlaceholder: "search_for_resident",
        hasNext: true,
        exportsData: true,
        rowsIcon: 'user',
        colData: [{ header: "dashboard_name", value: 'name' }, { header: "capital_sessions", value: 'sessions', textAlign: 'right', margin: "0 30px" }],
        isShowSeconds: false,
        isDesc: true,
        normalizeDataBySeconds: false,
        sortBy: "dashboard_name",
        contentType: false,
        showLastSeen: true,
        contextId: 'userId',
        saveCommentFunction: saveResidentComment,
        pinnedResidents: true,
        hasTagsInput: true,
        showAttentionAlert: true,
        uploadButton: false,
        videoInExpander: false,
        columnValue: COLUMN_VALUE_OPTION.METER,
        videoCurrentTime: null,
        subRowControls: false,
        exportInRow: false,
        useValencePS: false,
        hasDateFilter: true,
        sessionName: null,
        tagContext: TAG_CONTEXT.RESIDENT,
        annotationType: ANNOTATION_TYPE.HOUSE,
        relationId: null
    },
    reducers: {
        setTableData: (state, action) => {
            const data = modifyTableData(action.payload, COLUMN_VALUE_OPTION.METER)
            state.tableData = data || []
        },
        setAnnotations: (state, action) => {
            state.annotations = action.payload || []
        },
        setSearchPlaceholder: (state, action) => {
            state.currPlaceholder = action.payload
        },
        setAnnotationRelationType: (state, action) => {
            state.relationId = action.payload
        },
        setShowInsufficientData: (state, action) => {
            state.showInsufficientData = action.payload
        },
        setUserName: (state, action) => {
            state.userName = action.payload
        },
        setSelectedOvertimeButtons: (state, action) => {
            state.selectedOvertimeButtons[action.payload.id] = action.payload.data || []
        },
        setVideoCurrentTime: (state, action) => {
            state.videoCurrentTime = action.payload
        },
        pushToTableData: (state, action) => {
            state.tableData.push(action.payload)
        },
        removeFromTable: (state, action) => {
            state.tableData = state.tableData.filter(row => row.videoId !== action.payload)
        },
        editTableRow: (state, action) => {
            const { videoId, videoName } = action.payload
            state.tableData = state.tableData.map(row => {
                if (row.videoId === videoId) {
                    return { ...row, name: videoName };
                }
                return row;
            });
        },
        setTableOvertime: (state, action) => {
            state.tableOvertime[action.payload.rowId] = action.payload.data
            state.selectedOvertimeButtons[action.payload.rowId] = [OVER_TIME_MODULES_BUTTONS.WELLBEING]
        },
        selectTableOvertimeIds: (state, action) => {
            state.tableOvertimeSelectedIds[action.payload.rowId] = action.payload.data
        }
    },
    extraReducers: {
        [getUsersTableData.pending]: (state, action) => {
            state.loadingTable = true
        },
        [getUsersTableData.fulfilled]: (state, { payload }) => {
            state.loadingTable = false
            const columnValue = COLUMN_VALUE_OPTION.METER
            const data = modifyTableData(payload, columnValue)
            state.tableData = data || []

            state.headerTitle = "users"
            state.currPlaceholder = "search_for_resident"
            state.hasNext = true
            state.exportsData = true
            state.rowsIcon = 'user'
            state.colData = [{ header: "dashboard_name", value: 'name' }, { header: "capital_sessions", value: 'sessions', textAlign: 'right', margin: "0 30px" }]
            state.isShowSeconds = isSDK ? true : false
            state.isDesc = true
            state.normalizeDataBySeconds = false
            state.sortBy = "dashboard_name"
            state.contentType = false
            state.showLastSeen = true
            state.contextId = 'userId'
            state.saveCommentFunction = saveResidentComment
            state.pinnedResidents = true
            state.hasTagsInput = true
            state.showAttentionAlert = true
            state.uploadButton = false
            state.videoInExpander = false
            state.columnValue = columnValue
            state.useValencePS = true
            state.hasDateFilter = true
            state.sessionName = null
            state.userName = null
            state.tagContext = get(data, "[0].tagContext", null) //TAG_CONTEXT.RESIDENT
            state.subRowControls = false
            state.annotationType = ANNOTATION_TYPE.HOUSE
        },
        [getUsersTableData.rejected]: (state, action) => {
            console.error("error getting users table data", action)
            state.loadingTable = false
        },
        [getSessionsTableData.pending]: (state, { payload }) => {
            state.loadingTable = true
        },
        [getSessionsTableData.fulfilled]: (state, { payload }) => {
            state.loadingTable = false
            const columnValue = COLUMN_VALUE_OPTION.METER
            const data = modifyTableData(payload, columnValue)
            state.tableData = data || []

            let userName = get(data, "[0].userName", null)
            state.userName = userName

            state.headerTitle = "sessions"
            state.currPlaceholder = "search_for_date"
            state.hasNext = true
            state.exportsData = true
            state.rowsIcon = null
            state.colData = [{ header: "date", value: 'updatedAt' }, { header: "hour", value: 'updatedAt', textAlign: 'left', margin: '0 0 0 15px' }]
            state.isShowSeconds = isSDK ? true : false
            state.isDesc = true
            state.normalizeDataBySeconds = false
            state.sortBy = "date"
            state.contentType = true
            state.showLastSeen = false
            state.contextId = 'sessionId'
            state.saveCommentFunction = saveSessionComment
            state.pinnedResidents = false
            state.hasTagsInput = true
            state.showAttentionAlert = false
            state.uploadButton = false
            state.videoInExpander = false
            state.columnValue = columnValue
            state.useValencePS = false
            state.hasDateFilter = true
            state.sessionName = null
            state.tagContext = TAG_CONTEXT.SESSION
            state.subRowControls = false
            state.annotationType = ANNOTATION_TYPE.RESIDENT
        },
        [getSessionsTableData.rejected]: (state, { payload }) => {
            console.error("error getting sessions table data")
            state.loadingTable = false
        },
        [getContentTableData.pending]: (state, { payload }) => {
            state.loadingTable = true
        },
        [getContentTableData.fulfilled]: (state, { payload }) => {
            state.loadingTable = false
            const columnValue = COLUMN_VALUE_OPTION.METER
            const data = modifyTableData(payload, columnValue)

            state.tableData = data || []

            let contentDate = get(data, "[0].createdAt", 0)
            state.sessionName = moment(contentDate).format("DD.MM.YYYY")

            state.headerTitle = "videos"
            state.currPlaceholder = "search_for_content"
            state.hasNext = false
            state.exportsData = features.exportContentTable
            state.rowsIcon = 'index'
            state.colData = [{ header: "dashboard_name", value: 'title' }, { header: "artist", value: 'author', textAlign: 'left', margin: '0 0 0 20px' }]
            state.isShowSeconds = true
            state.isDesc = false
            state.normalizeDataBySeconds = true
            state.sortBy = "date"
            state.contentType = null
            state.showLastSeen = false
            state.contextId = null
            state.saveCommentFunction = null
            state.pinnedResidents = false
            state.hasTagsInput = false
            state.showAttentionAlert = false
            state.uploadButton = false
            state.videoInExpander = false
            state.columnValue = columnValue
            state.useValencePS = false
            state.hasDateFilter = false
            state.tagContext = null
            state.subRowControls = false
            state.annotationType = ANNOTATION_TYPE.SESSION
        },
        [getContentTableData.rejected]: (state, { payload }) => {
            console.error("error getting content table data")
            state.loadingTable = false
        },
        [getTableOverTimeData.pending]: (state, { payload, meta }) => {
            state.loadingRowOvertime[meta.arg.rowId] = true
        },
        [getTableOverTimeData.fulfilled]: (state, { payload, meta }) => {
            state.loadingRowOvertime[meta.arg.rowId] = false
            state.tableOvertime[meta.arg.rowId] = payload
            state.selectedOvertimeButtons[meta.arg.rowId] = [OVER_TIME_BUTTONS.WELLBEING]
        },
        [getTableOverTimeData.rejected]: (state, { payload, meta }) => {
            state.loadingRowOvertime[meta.arg.rowId] = false
            console.error("error getTableOverTimeData")
        },
        [getVideosTableData.pending]: (state, { payload }) => {
            state.loadingTable = true
        },
        [getVideosTableData.fulfilled]: (state, { payload }) => {

            console.log("getVideosTableData.fulfilled")
            state.loadingTable = false

            let {currentHouseType, activeHouse} = payload

            const columnValue = COLUMN_VALUE_OPTION.METER
            const data = modifyTableData(payload.videos, columnValue, currentHouseType, activeHouse)

            state.tableData = data || []

            state.headerTitle = "videos"
            state.currPlaceholder = "search_for_content"
            state.hasNext = false
            state.rowsIcon = 'user'
            state.colData = [{ header: "dashboard_name", value: 'name' }, { header: "date", value: 'videoDate', textAlign: "right", margin: "0 25px", justifyContent: 'end', headerMargin: 15 }, { header: "time", value: 'videoDate', textAlign: "right", margin: "0 25px", justifyContent: 'end', headerMargin: 15 }]
            state.isShowSeconds = true
            state.isDesc = true
            state.normalizeDataBySeconds = false
            state.sortBy = "date"
            state.contentType = false
            state.showLastSeen = false
            state.contextId = 'videoId'
            state.saveCommentFunction = saveSessionComment
            state.pinnedResidents = true
            state.hasTagsInput = true
            state.showAttentionAlert = true
            state.uploadButton = true
            state.videoInExpander = false //houseType === HOUSE_TYPES.upload ? true : false
            state.columnValue = columnValue
            state.subRowControls = true
            state.exportInRow = true
            state.useValencePS = false
            state.hasDateFilter = true
            state.sessionName = null
            state.userName = null
            state.tagContext = TAG_CONTEXT.VIDEO
            state.annotationType = ANNOTATION_TYPE.VIDEO
        },
        [getVideosTableData.rejected]: (state, { payload }) => {
            console.error("error getting videos table data")
            state.loadingTable = false
        },
        [getTableVideoData.pending]: (state, { payload, meta }) => {
            console.log("getTableVideoData.pending")
            state.loadingRowOvertime[meta.arg.rowId] = true
        },
        [getTableVideoData.fulfilled]: (state, { payload, meta }) => {
            console.log("getTableVideoData.fulfilled")
            state.tableOvertime[meta.arg.rowId] = payload.overTime.all
            let overtimeIds = Object.keys(payload.overTime).filter(key => key !== "all");
            state.tableOvertimeIds[meta.arg.rowId] = overtimeIds
            let defaultId = overtimeIds.length > 0 ? [overtimeIds[0]] : null;
            state.tableOvertimeSelectedIds[meta.arg.rowId] = overtimeIds
            state.tableVideo[meta.arg.rowId] = payload.videoUrl
            state.selectedOvertimeButtons[meta.arg.rowId] = [OVER_TIME_BUTTONS.WELLBEING]
            state.loadingRowOvertime[meta.arg.rowId] = false
        },
        [getTableVideoData.rejected]: (state, { payload, meta }) => {
            state.loadingRowOvertime[meta.arg.rowId] = false
            console.error("error getTableVideoData")
        },
        [saveVideoData.pending]: (state, { payload, meta }) => {
        },
        [saveVideoData.fulfilled]: (state, { payload, meta }) => {
            const index = payload.index
            const row = payload.res
            const sufficientData = calcSufficientData(payload.res, COLUMN_VALUE_OPTION.METER)
            row.sufficientData = sufficientData
            state.tableData[index] = row
        },
        [saveVideoData.rejected]: (state, { payload, meta }) => {
            console.error("error saveVideoData")
        },
    }
});

const { actions, reducer } = tableSlice;

export const { setTableData, setSelectedOvertimeButtons, setVideoCurrentTime, pushToTableData,
    setTableOvertime, removeFromTable, editTableRow, setUserName, setShowInsufficientData,
    setAnnotations, setAnnotationRelationType, setSearchPlaceholder } = actions;
export default reducer;
